.AppText {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: var(--fw-r);
  font-size: var(--fs-t-lg);
  line-height: 1.6;
  color: var(--color-gray-800);

  @media (max-width: 1440px) {
    font-size: var(--fs-t-md);
  }

  @media (max-width: 768px) {
    font-size: var(--fs-t-sm);
  }
}
